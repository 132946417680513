export default class AppHeader {
  constructor(container, options) {
    const defaultOptions = {
      mobileNavigation: false
    }

    this.options = Object.assign({}, defaultOptions, options)
    this.app = document.querySelector('.app')
    this.container = document.querySelector(container)
    this.mobileNavigation = this.options.mobileNavigation
    this.hamburgerMenu = this.container.querySelector('.icon-hamburger-menu')
    this.headingOverlayDesktop = document.querySelectorAll('.heading-overlay-desktop')

    this.hamburgerMenu.addEventListener('click', this.onClickHamburgerMenu.bind(this))
    this.updateMobileNavigationClass()
    this.setEvents()
  }

  setEvents() {
    this.setActiveNavigationItem()
    this.onPageScroll()
  }

  onClickHamburgerMenu () {
    this.mobileNavigation = !this.mobileNavigation
    this.updateMobileNavigationClass()
  }

  updateMobileNavigationClass() {
    if (this.mobileNavigation) {
      this.hamburgerMenu.classList.add('mobile')
      this.container.classList.add('active')
    } else {
      this.hamburgerMenu.classList.remove('mobile')
      this.container.classList.remove('active')
    }
  }

  setActiveNavigationItem() {
    const path = window.location.pathname
    const links = this.container.querySelectorAll('a')
  
    links.forEach(link => {
      const href = link.getAttribute('href').replace(/\/$/, '')
      const currentPath = path.replace(/\/$/, '')
  
      if (currentPath.includes(href) && href !== '') {
        link.classList.add('active')
      } else if (href === '/' && currentPath === '') {
        link.classList.add('active')
      } else {
        link.classList.remove('active')
      }
    })
  }

  onPageScroll () {
    const app = this.app
    const header = this.container
    const headerHeight = 0
    const scrollClass = 'scroll-down'
    const headingOverlayDesktop = this.headingOverlayDesktop

    function updateScrollClass() {
        if (window.scrollY > headerHeight) {
            app.classList.add(scrollClass)
            header.classList.add(scrollClass)

            headingOverlayDesktop.forEach(overlay => {
              overlay.classList.add(scrollClass)
            })
        } else {
            app.classList.remove(scrollClass)
            header.classList.remove(scrollClass)
            
            headingOverlayDesktop.forEach(overlay => {
              overlay.classList.remove(scrollClass)
            })
        }
    }

    updateScrollClass()

    window.addEventListener("scroll", updateScrollClass)
  }
}